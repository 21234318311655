<template>
  <Page class="page-pricings page-show-pricing">
    <template #title>Dettagli pricing</template>

    <template #title-extra>
      <router-link class="btn btn-outline-light" :to="{ name: 'pricings.edit' }">Modifica</router-link>
    </template>

    <template #default>
      <div class="table-responsive">
        <table class="table table-condensed">
          <tbody>
          <tr>
            <th>Nome</th>
            <td>{{ pricing.name }}</td>
          </tr>
          <tr>
            <th>Costo di attivazione</th>
            <td>{{ pricing.activation_price | currency(false) }} €</td>
          </tr>
          <tr>
            <th>Costo mensile</th>
            <td>{{ pricing.monthly_price | currency(false) }} €</td>
          </tr>
          <tr>
            <th>Fee fissa</th>
            <td>{{ pricing.fixed_fee | currency(false) }} €</td>
          </tr>
          <tr>
            <th>Fee percentuale</th>
            <td>{{ pricing.percent_fee | percentage }} %</td>
          </tr>
          <tr>
            <th>Attivabile</th>
            <td>{{ pricing.enabled ? 'Sì' : 'No' }}</td>
          </tr>
          <tr>
            <th>Elencato pubblicamente</th>
            <td>{{ pricing.listed ? 'Sì' : 'No' }}</td>
          </tr>
          <tr>
            <th>Predefinito</th>
            <td>{{ pricing.default ? 'Sì' : 'No' }}</td>
          </tr>
          <tr>
            <th>Coupon di attivazione</th>
            <td>{{ pricing.activatingCoupon }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
  </Page>
</template>

<script>

import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal.js';
import formatPercentage from '@/filters/formatPercentage.js';
import formatCurrency from '@/filters/formatCurrency.js';

import Page from '@/views/components/Private/Page.vue';

export default {
  mixins: [confirmWithModalMixin],
  filters: {
    percentage: formatPercentage,
    currency: formatCurrency,
  },
  components: {
    Page,
  },
  props: {
    pricing: {
      type: Object,
      required: true,
    },
  },
};

</script>
